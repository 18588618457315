// import CourtBG from '@/assets/bg/baseball-field-omg.svg';

const posX = 77;
const posY = -16;
const sizeY = 140;

// https://plotly.com/javascript/images/
const baseballCourtBG = {
  source: '/img/bg/baseball-field-omg.png',
  xref: 'x',
  yref: 'y',
  x: -posX,
  y: posY,
  sizex: posX * 2,
  sizey: sizeY,
  opacity: 0.4,
  layer: 'below',
  xanchor: 'left',
  yanchor: 'bottom',
};

const sb_ratio = 1216 / 902; // Original file size
const sb_offset_img_correction = 0.25;
const sb_sizeX = 105;
const sb_sizeY = sb_sizeX * sb_ratio;
const sb_posX = sb_sizeX * -0.5 - sb_offset_img_correction;
const sb_posY = -3;

const softballCourtBg = {
  source: '/img/bg/softball-field.png',
  xref: 'x',
  yref: 'y',
  x: sb_posX,
  y: sb_posY,
  sizex: sb_sizeX,
  sizey: sb_sizeY,
  opacity: 0.4,
  layer: 'below',
  xanchor: 'left',
  yanchor: 'bottom',
};

// 492.3 878.8
const releasePointPlayer = {
  source: '/img/bg/release-point-player.png',
  rightHandedSource: '/img/bg/release-point-player.png',
  leftHandedSource: '/img/bg/release-point-player-left.png',
  xref: 'x',
  yref: 'y',
  x: -0.5,
  y: 0,
  sizex: 1.0083522985889852,
  sizey: 1.8,
  // opacity: 0.4,
  layer: 'below',
  xanchor: 'left',
  yanchor: 'bottom',
};

// 278x982 = 0.2830957230142566 (ratio)
const releasePointPlayerSoftball = {
  source: '/img/bg/softball-release-point-player.png',
  rightHandedSource: '/img/bg/softball-release-point-player.png',
  leftHandedSource: '/img/bg/softball-release-point-player-left.png',
  xref: 'x',
  yref: 'y',
  x: -0.5,
  y: 0,
  sizex: 0.6794297352342159, // ratio * sizey
  sizey: 2.4,
  layer: 'below',
  xanchor: 'left',
  yanchor: 'bottom',
};

// 998.1 886
const releaseExtensionPlayer = {
  source: '/img/bg/release-extension-player.png',
  xref: 'x',
  yref: 'y',
  x: 0,
  y: 0,
  sizex: 1.915,
  sizey: 1.7,
  layer: 'below',
  xanchor: 'left',
  yanchor: 'bottom',
};

// 374x956 = 0.3912133891213389 (ratio)
const releaseExtensionPlayerSoftball = {
  source: '/img/bg/softball-release-extension-player.png',
  xref: 'x',
  yref: 'y',
  x: 0,
  y: 0,
  sizex: 0.7824267782426778, // ratio * sizey
  sizey: 2.0,
  layer: 'below',
  xanchor: 'left',
  yanchor: 'bottom',
};

const battedBallQualityPlayer = {
  x: 0.2,
  y: -0.05,
  sizex: 2,
  sizey: 1,
  source: "/img/bg/batted-batter.svg",
  xanchor: "right",
  xref: "paper",
  yanchor: "bottom",
  yref: "paper"
};

const size = 17;
const baseballPlateBG = {
  source: '/img/bg/baseball-plate.svg',
  x: 0,
  xanchor: 'center',
  y: 0,
  yanchor: 'top',
  sizex: size,
  sizey: size,
  layer: 'above',
  xref: 'x',
  yref: 'y'
};

export default {
  baseballCourtBG,
  softballCourtBg,
  releasePointPlayer,
  releaseExtensionPlayer,
  releaseExtensionPlayerSoftball,
  battedBallQualityPlayer,
  releasePointPlayerSoftball,
  baseballPlateBG
};
